import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { JwtAuthService } from './auth/jwt-auth.service';
import { LocalStoreService } from './local-store.service';
interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  flag?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService implements OnInit {
  iconMenu: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'link',
      tooltip: 'Home',
      svgIcon: 'home1',
      state: 'Home',
    },
  
    {
      name: 'Operations',
      type: 'dropDown',
      tooltip: 'Tables',
      state: 'operation',
      svgIcon: 'setting',
      sub: [
        { name: 'Device Inventory', type: 'link', state: 'inventory' },
        { name: 'Decommissioned & Removed', type: 'link', state: 'decommissioned' },
        { name: 'Spare Devices', type: 'link', state: 'spare' },
        { name: 'Network Switch', type: 'link', state: 'Switch' },
        { name: 'Servers', type: 'link', state: 'server' },
      ],
    },
    {
      name: 'Administration',
      type: 'dropDown',
      tooltip: 'Tables',
      state: 'administration',
      svgIcon: 'usersS',
      sub: [
        { name: 'Countries', type: 'link', state: 'Countries' },
        { name: 'Device Type', type: 'link', state: 'deviceType' },
        { name: 'Manufacturers', type: 'link', state: 'Manufacturers' },
        { name: 'Materials & Services', type: 'link', state: 'MS' },
        { name: 'Sites', type: 'link', state: 'Sites' },
        { name: 'Users', type: 'link', state: 'users' },
        { name: 'User Operations', type: 'link', state: 'UserOperation' },
      ],
    },
    {
      name: 'Reports',
      tooltip: 'Reports',
      state: 'Reports/Reports',
      svgIcon: 'ulb_documents',
      type: 'link'
    },
     {
      name: 'Planned Preventative maintenance',
      type: 'dropDown',
      tooltip: 'Planned Preventative maintenance',
      state: 'ppm',
      svgIcon: 'ppm',
      sub: [
        { name: 'PPM Reporrts', type: 'link', state: 'ppm-reports' },
        { name: 'Generate PPM', type: 'link', state: 'ppm-generate' },
        
      ],
    },
    {
      name: 'Service Requests',
      type: 'dropDown',
      tooltip: 'Service Requests',
      state: 'service-requests',
      svgIcon: 'service',
      sub: [
        { name: 'Companies', type: 'link', state: 'Companies' },
        { name: 'Company Service', type: 'link', state: 'services' },
        { name: 'Incident Priority', type: 'link', state: 'Incident-priority' },
        { name: 'Resolution', type: 'link', state: 'resolution' },
        { name: 'Resolution Category', type: 'link', state: 'resolutionCategory' },
        { name: 'ServiceType', type: 'link', state: 'serviceTypes' },
      ],
    },
   
    {
      name: 'Tickets',
      tooltip: 'Tickets',
      state: 'ticket',
      svgIcon: 'tickets',
      type: 'dropDown',
      sub: [
        { name: 'Ticket Dashboard', type: 'link', state: 'dashboard-tickets' },
        { name: 'Ticket Operation', type: 'link', state: '' },
      ]
    },
  ];
  iconMenuNotAdmin: IMenuItem[] = [
    {
      name: 'HOME',
      type: 'link',
      tooltip: 'Home',
      svgIcon: 'home1',
      state: 'Home',
    },
  
    {
      name: 'Operations',
      type: 'dropDown',
      tooltip: 'Tables',
      state: 'operation',
      svgIcon: 'setting',
      sub: [
        { name: 'Device Inventory', type: 'link', state: 'inventory' },
        { name: 'Decommissioned & Removed', type: 'link', state: 'decommissioned' },
        { name: 'Spare Devices', type: 'link', state: 'spare' },
        { name: 'Network Switch', type: 'link', state: 'Switch' },
      ],
    },

    {
      name: 'Operations',
      type: 'dropDown',
      tooltip: 'Table',
      state: 'operation',
      svgIcon: 'setting',
      sub: [
        { name: 'Device Inventory', type: 'link', state: 'inventory' },
        { name: 'Decommissioned & Removed', type: 'link', state: 'decommissioned' },
        { name: 'Spare Devices', type: 'link', state: 'spare' },
        { name: 'Network Switch', type: 'link', state: 'Switch' },
        { name: 'Servers', type: 'link', state: 'server' },

      ],
    },
    {
      name: 'Reports',
      tooltip: 'Reports',
      state: 'Reports/Reports',
      svgIcon: 'ulb_documents',
      type: 'link'
    },
    {
      name: 'Planned Preventative maintenance',
      type: 'dropDown',
      tooltip: 'Planned Preventative maintenance',
      state: 'ppm',
      svgIcon: 'ppm',
      sub: [
        { name: 'PPM Reporrts', type: 'link', state: 'ppm-reports' },
        { name: 'Generate PPM', type: 'link', state: 'ppm-generate' },
        
      ],
    },
    {
      name: 'Tickets',
      tooltip: 'Tickets',
      state: 'ticket',
      svgIcon: 'tickets',
      type: 'dropDown',
      sub: [
        { name: 'Ticket Dashboard', type: 'link', state: 'dashboard-tickets' },
        { name: 'Ticket Operation', type: 'link', state: '' },
      ]
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  menuItemsNotAdmin = new BehaviorSubject<IMenuItem[]>(this.iconMenuNotAdmin);
  // navigation component has subscribed to this Observable
  menuItemsNotAdmin$ = this.menuItemsNotAdmin.asObservable();
  sites: any[] = [];
  sub: any[] = [];
  constructor(private jwt: JwtAuthService, private api: ApiService, private ls: LocalStoreService) {
    this.api.getObservable().subscribe((data) => {
      if (data.serviceId > 0) {
        this.api.getCountry().subscribe((res) => {
          if (res.status == 200) {
            this.jwt.Countries = res.body.sites;
            ApiService.serviceId = data.serviceId;
            this.ls.setItem('Sites', res.body.sites);
            this.extractSitesMenu(res.body.sites);
          }
        });
      }
    });
    if (this.jwt.Countries.length > 0 && this.iconMenu[this.iconMenu.length - 1].name !== 'Sites') {
      this.extractSitesMenu(this.jwt.Countries);
    }
    if (this.jwt.Countries.length > 0 && this.iconMenuNotAdmin[this.iconMenuNotAdmin.length - 1].name !== 'Sites') {
      this.extractSitesMenu(this.jwt.Countries);
    }
  }

  ngOnInit() { }

  extractSitesMenu(sites: any[]) {
    let sub = [];
    sites?.forEach((ele: any) => {
      let Sites = [];
      ele.sites.forEach((site) => {
        // Create site menu item with proper state (siteId)
        let rowSite: any = { 
          name: site?.siteName, 
          type: 'link', 
          state: site?.siteId,
          countryId: ele?.countryInfo?.countryId // Store countryId for reference
        };
        Sites.push(rowSite);
      });
      
      // Create country menu item with proper state (countryId)
      let row: any = {
        name: ele?.countryInfo?.countryName,
        flag: ele?.countryInfo?.countryFlag,
        type: 'dropDown',
        sub: Sites,
        state: ele?.countryInfo?.countryId,
      };
      sub.push(row);
    });
    
    this.sites = sites;
    
    // Remove existing Sites menu item if it exists
    if (this.iconMenu[this.iconMenu.length - 1].name === 'Sites') {
      this.iconMenu.pop();
    }
    if (this.iconMenuNotAdmin[this.iconMenuNotAdmin.length - 1].name === 'Sites') {
      this.iconMenuNotAdmin.pop();
    }

    // Add updated Sites menu item
    this.iconMenu.push({
      name: 'Sites',
      type: 'dropDown',
      tooltip: 'Sites',
      state: 'Home/dashboard',
      svgIcon: 'sites',
      sub: sub,
    });
    
    this.iconMenuNotAdmin.push({
      name: 'Sites',
      type: 'dropDown',
      tooltip: 'Sites',
      state: 'Home/dashboard',
      svgIcon: 'sites',
      sub: sub,
    });
    
    // Publish the updated menu items
    this.menuItems.next(this.iconMenu);
    this.menuItemsNotAdmin.next(this.iconMenuNotAdmin);
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    // this.menuItems.next(this.separatorMenu);
    // break;
    //   case 'icon-menu':
    this.menuItems.next(this.iconMenu);
    this.menuItemsNotAdmin.next(this.iconMenuNotAdmin);
    // break;
    //   default:
    // this.menuItems.next(this.plainMenu);
    // }
  }
}
