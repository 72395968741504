import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'app/shared/services/api.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor( public router: Router, public api: ApiService) {}
  
  ngOnInit() {
    
  }

  // Navigate to country dashboard
  navigateToCountry(state: string, countryId: number) {
    this.router.navigate([state, ApiService.serviceId], {
      queryParams: { country: countryId }
    });
    return false; // Prevent default behavior
  }

  // Navigate to site dashboard
  navigateToSite(state: string, siteId: number) {
    this.router.navigate([state, ApiService.serviceId], {
      queryParams: { site: siteId }
    });
    return false; // Prevent default behavior
  }
  
  // Navigate to site within a country
  navigateToSiteInCountry(state: string, countryId: number, siteId: number) {
    this.router.navigate([state, ApiService.serviceId], {
      queryParams: { country: countryId, site: siteId }
    });
    return false; // Prevent default behavior
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: 'ITEM',
      type: 'dropDown',
      tooltip: 'Item',
      icon: 'done',
      state: 'material',
      sub: [
        {name: 'SUBITEM', state: 'cards'},
        {name: 'SUBITEM', state: 'buttons'}
      ]
    });
  }
}