import { Injectable } from '@angular/core';
import { Observable, Subject, throwError, of } from 'rxjs';
import { catchError, tap, shareReplay, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Page } from '../../shared/models/common.model';
import { SearchParam as SearchParams } from '../../shared/models/macList.model';
import { SearchParam } from '../models/dataTable.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStoreService } from './local-store.service';
import { DeviceUUID } from 'device-uuid';
import { searchUser, UserOperationSearch } from '../models/user.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements HttpInterceptor {
  // Add cache storage
  private cache = new Map<string, any>();
  private cacheTTL = 60000; // 1 minute cache TTL

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        // Handle authentication errors
        if (error.status === 401) {
          const refresh = this.ls.getItem('REF_JWT_TOKEN');
          if (refresh) {
            // Handle token refresh more efficiently
            this.handleTokenRefresh(refresh);
          } else {
            this.handleLogout(error);
          }
        } 
        // Handle session expiration
        else if (error.status === 456) {
          this.handleSessionExpiration();
        } 
        // Handle other errors
        else {
          this.handleGenericError(error);
        }
        
        return throwError(error);
      })
    );
  }

  // Helper methods to simplify the interceptor
  private handleTokenRefresh(refreshToken: string): void {
    this.refreshToken(refreshToken).subscribe(
      (res) => {
        if (res.status === 200) {
          ApiService.Token = res.body.token;
          ApiService.RefreshToken = res.body.token;
          this.ls.setItem('JWT_TOKEN', res.body.token);
          window.location.reload();
        } else {
          this.logout(false);
          this.showErrorMessage('Token refresh failed');
        }
      },
      (error) => {
        this.logout(false);
        this.showErrorMessage('Token refresh failed');
      }
    );
  }

  private handleLogout(error: any): void {
    this.logout(false);
    this.showErrorMessage(error.error?.message || 'Authentication error');
  }

  private handleSessionExpiration(): void {
    this.ls.clear();
    ApiService.Token = '';
    ApiService.access_type = '';
    this.router.navigate(['sessions/signin']);
    this.showErrorMessage('Your session has expired');
  }

  private handleGenericError(error: any): void {
    const errorMessage = error.error?.message?.message || error.error?.message || 'Error occurred';
    this.showErrorMessage(errorMessage);
    
    // Show detailed validation errors if available
    if (error.error?.message?.details?.body) {
      const detailedMessage = error.error.message.details.body
        .map(element => element?.message)
        .filter(Boolean)
        .join(' ');
        
      if (detailedMessage) {
        this.showErrorMessage(detailedMessage);
      }
    }
  }

  private showErrorMessage(message: string): void {
    this.snack.open(message, 'Error', { duration: 4000 });
  }

  isProd = false;

  // baseURL = 'https://support.api.ldcs.ae/api';
  // stagingBaseURL = 'https://stagging.support.api.ldcs.ae/api';
  apiUrl: string = '';
  // baseURLImage = 'https://support.api.ldcs.ae';
  // stagingBaseURLImage = 'https://stagging.support.api.ldcs.ae';
  apiUrlImage: string = '';
  timeeOutDuration = `${50000}`;
  headers = new HttpHeaders();
  static Token: string = '';
  static RefreshToken: string = '';
  static access_type: string = 'bearer';
  static user_type: number = -1;
  static serviceId: number = 1;
  constructor(
    private http: HttpClient,
    private router: Router,
    private snack: MatSnackBar,
    private ls: LocalStoreService
  ) {
    this.apiUrl = environment.baseURL;
    this.apiUrlImage = environment.baseURLImage;
    if (this.isProd) {
      if (window) {
      }
    } else {
    }
  }

  //////// Events to broadcast ///////
  private UserUpdated = new Subject<any>();

  public publishSomeData(data: any) {
    this.UserUpdated.next(data);
  }

  public getObservable(): Subject<any> {
    return this.UserUpdated;
  }

  //Logout
  logout(allDevices: boolean) {
    let uuid = this.ls.getItem('uuid');
    let data: any = {
      uuid: uuid,
      allDevices: allDevices,
    };
    let requestBody = JSON.stringify(data);
    return this.postData(requestBody, 'user/auth/logout');
  }
  //signIn
  Login(username: string, password: string, uuid: string): Observable<any> {
    // const uuid = new DeviceUUID().get();
    const du = new DeviceUUID().parse();
    let data: any = {
      username: username,
      password: password,
      platform: du.platform,
      uuid: uuid,
    };
    let requestBody = JSON.stringify(data);
    return this.postData(requestBody, 'user/auth/login');
  }

  refreshToken(refreshToken: string): Observable<any> {
    let data: any = {
      refreshToken: refreshToken,
      uuid: this.ls.getItem('uuid'),
    };
    let requestBody = JSON.stringify(data);
    return this.postData(requestBody, 'user/auth/refresh-token');
  }

  // get
  getData(API_NAME: String, useCache: boolean = true): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    
    // Check cache first if caching is enabled (but only log in development)
    if (useCache && this.cache.has(API_URL)) {
      const cachedData = this.cache.get(API_URL);
      if (cachedData.timestamp > Date.now() - this.cacheTTL) {
        // Return cached data without logging to improve performance
        return of(cachedData.data);
      } else {
        this.cache.delete(API_URL); // Cache expired
      }
    }
    
    // Create headers only once
    if (!this.headers || this.headers.keys().length === 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', '*/*');
    }
    
    // Add timeout to prevent hanging requests
    return this.http.get(API_URL, { 
      headers: this.headers, 
      observe: 'response'
    }).pipe(
      // Add timeout operator to prevent hanging requests
      timeout(30000), // 30 seconds timeout
      catchError((err) => {
        // Handle the error here
        return throwError(err); // Rethrow it back to component
      }),
      tap((response) => {
        // Cache the response only if caching is enabled
        if (useCache) {
          this.cache.set(API_URL, {
            data: response,
            timestamp: Date.now()
          });
        }
      })
    );
  }
  //post
  postData(data: any, API_NAME: String, headers?: any): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    
    // Set headers only once if not already set
    if (!this.headers || this.headers.keys().length === 0 || headers) {
      if (ApiService.Token.length > 0) {
        this.headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
          .set('Accept', 'application/json');
        
        if (headers) {
          this.headers = this.headers.set(headers.key, headers.value);
        }
      } else {
        this.headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/json');
          
        if (headers) {
          this.headers = this.headers.set(headers.key, headers.value);
        }
      }
    }

    return this.http.post(API_URL, data, { headers: this.headers, observe: 'response' }).pipe(
      // Add timeout operator to prevent hanging requests
      timeout(30000), // 30 seconds timeout
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  // patch
  patchData(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
      
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json'); 
    }
    return this.http.patch(API_URL, data, { headers: this.headers, observe: 'response' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // delete
  deleteData(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
     
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');

    }
    return this.http.delete(API_URL, { headers: this.headers, observe: 'response', body: data }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  putData(data: any, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
    
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
     
    }
    return this.http.put(API_URL, data, { headers: this.headers, observe: 'response' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // post from data
  postFromData(data: FormData, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    
    // Set headers for form data
    const headers = new HttpHeaders();
    if (ApiService.Token.length > 0) {
      headers.set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
            .set('Accept', 'application/json');
    }
    
    return this.http.post(API_URL, data, { 
      headers: headers, 
      observe: 'response' 
    }).pipe(
      // Add timeout operator to prevent hanging requests
      timeout(30000), // 30 seconds timeout
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  putFromData(data: FormData, API_NAME: String): Observable<any> {
    let API_URL = `${this.apiUrl}/${API_NAME}`;
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    }
    return this.http.put(API_URL, data, { headers: this.headers, observe: 'response' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getDeviceByType() {
    let ApiUrl = `dashboard/devices/count-per-type`;
    return this.getData(ApiUrl);
  }
  getDeviceBySwitch(siteId: number) {
    let ApiUrl = `dashboard/devices/count-per-switch?serviceType=${ApiService.serviceId}`;
    if (siteId > 0) {
      ApiUrl += `&siteId=${siteId}`;
    }
    return this.getData(ApiUrl);
  }
  getServers(siteId: number) {
    let ApiUrl = `dashboard/devices/count-per-server?serviceType=${ApiService.serviceId}`;
    if (siteId > 0) {
      ApiUrl += `&siteId=${siteId}`;
    }
    return this.getData(ApiUrl);
  }

  getSites() {
    let ApiUrl = `site`;
    return this.getData(ApiUrl);
  }
  getLastTenTicket() {
    let ApiUrl = `ticket/last-tickets`;
    return this.getData(ApiUrl);
  }
  getOperation() {
    let ApiUrl = `operation`;
    return this.getData(ApiUrl);
  }
  getServices() {
    let ApiUrl = `service-type`;
    return this.getData(ApiUrl,false);
  }
  getCountry() {
    let ApiUrl = `dashboard/devices/sites?serviceType=${ApiService.serviceId}`;
    return this.getData(ApiUrl);
  }
  getTypes() {
    let ApiUrl = `dashboard/devices/types`;
    return this.getData(ApiUrl);
  }
  getStatus() {
    let ApiUrl = `dashboard/devices/status`;
    return this.getData(ApiUrl);
  }
  getstaticData(siteId: number, countryId: number, serviceType: number) {
    let ApiUrl = `dashboard/devices/statistics?`;
    
    // Add serviceType parameter if provided
    if (serviceType > 0) {
      ApiUrl += `serviceType=${serviceType}`;
    } else {
      // Default to serviceId 1 if not provided to ensure we get data
      ApiUrl += `serviceType=${ApiService.serviceId || 1}`;
    }
    
    // Add countryId parameter if provided
    if (countryId > 0) {
      ApiUrl += `&countryId=${countryId}`;
    }
    
    // Add siteId parameter if provided
    if (siteId > 0) {
      ApiUrl += `&siteId=${siteId}`;
    }

    console.log('Calling statistics API with URL:', ApiUrl);
    
    // Clear cache for this endpoint to ensure fresh data
    this.clearCacheEntry(ApiUrl);
    
    return this.getData(ApiUrl).pipe(
      catchError(error => {
        console.error('Error in statistics API call:', error);
        return throwError(error);
      }),
      tap(response => {
        if (response && response.body) {
          console.log('Statistics API response received successfully');
        } else {
          console.warn('Statistics API returned empty or invalid response');
        }
      })
    );
  }
  //Inventory mangment
  getDeviceInventory(paginator: Page, search: SearchParam, serviceId: number, sortParam: any) {
    let ApiUrl = `device-inventory/get-devices-by-page?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (serviceId >= 0) {
      ApiUrl += `&serviceType=${serviceId}`;
    }
    if (search.siteId > 0) {
      ApiUrl += `&siteId=${search.siteId}`;
    }
    if (search.manufacturerId > 0) {
      ApiUrl += `&manufacturerId=${search.manufacturerId}`;
    }
    if (search.deviceTypeId > 0) {
      ApiUrl += `&deviceTypeId=${search.deviceTypeId}`;
    }
    if (search.deviceStatusCode != null && search.deviceStatusCode != '') {
      ApiUrl += `&deviceStatusCode=${search.deviceStatusCode}`;
    }
    if (sortParam.sortType > 0) {
      ApiUrl += `&sortType=${sortParam.sortType}`;
    }
    if (sortParam.columnName != null && sortParam.columnName != '') {
      ApiUrl += `&columnName=${sortParam.columnName}`;
    }
    if (search.switchName != null && search.switchName != '') {
      ApiUrl += `&switchName=${search.switchName}`;
    }
    if (search.portDetails != null && search.portDetails != '') {
      ApiUrl += `&portDetails=${search.portDetails}`;
    }
    if (search.deviceIP != null && search.deviceIP != '') {
      ApiUrl += `&deviceIP=${search.deviceIP}`;
    }
    if (search.macAddress != null && search.macAddress != '') {
      ApiUrl += `&macAddress=${search.macAddress}`;
    }
    if (search.barcode != null && search.barcode != '') {
      ApiUrl += `&barcode=${search.barcode}`;
    }
    if (search.deviceName != null && search.deviceName != '') {
      ApiUrl += `&deviceName=${search.deviceName}`;
    }
    if (search.serverNumber != null && search.serverNumber != '') {
      ApiUrl += `&serverNumber=${search.serverNumber}`;
    }
    return this.getData(ApiUrl);
  }
  addDeviceinventory(AddData: any) {
    let ApiUrl = `device-inventory/store`;
    let data: any = {
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateDeviceinventory(AddData: any) {
    let ApiUrl = `device-inventory/update`;
    let data: any = {
      deviceId: AddData.deviceId,
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  deleteDeviceInventory(id: number) {
    let ApiUrl = `device-inventory/delete`;
    let data: any = {
      deviceId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  //Mac List
  getDeviceMacList(paginator: Page, search: SearchParams) {
    let ApiUrl = `device-mac-list/get-device-mac-list-by-page?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    if (search.macAddress != null && search.macAddress != '') {
      ApiUrl += `&macAddress=${search.macAddress}`;
    }
    if (search.portDetails != null && search.portDetails != '') {
      ApiUrl += `&portDetails=${search.portDetails}`;
    }
    if (search.switchName != null && search.switchName != '') {
      ApiUrl += `&switchName=${search.switchName}`;
    }
    return this.getData(ApiUrl);
  }

  addDeviceMac(AddData: any) {
    let ApiUrl = `device-mac-list/store`;
    let data: any = {
      macAddress: AddData.macAddress,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateDeviceMac(AddData: any) {
    let ApiUrl = `device-mac-list/update`;
    let data: any = {
      deviceMacListId: AddData.deviceMacListId,
      macAddress: AddData.macAddress,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  deleteDeviceMac(id: number) {
    let ApiUrl = `device-mac-list/delete`;
    let data: any = {
      deviceMacListId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  getDemmissionedDeviceInventory(paginator: Page, search: SearchParam, serviceId: number, sortParam: any) {
    let ApiUrl = `decom-rmv?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (serviceId >= 0) {
      ApiUrl += `&serviceType=${serviceId}`;
    }
    if (search.siteId > 0) {
      ApiUrl += `&siteId=${search.siteId}`;
    }
    if (search.manufacturerId > 0) {
      ApiUrl += `&manufacturerId=${search.manufacturerId}`;
    }
    if (search.deviceTypeId > 0) {
      ApiUrl += `&deviceTypeId=${search.deviceTypeId}`;
    }
    if (search.deviceStatusCode != null && search.deviceStatusCode != '') {
      ApiUrl += `&deviceStatusCode=${search.deviceStatusCode}`;
    }
    if (search.switchName != null && search.switchName != '') {
      ApiUrl += `&switchName=${search.switchName}`;
    }
    if (search.portDetails != null && search.portDetails != '') {
      ApiUrl += `&portDetails=${search.portDetails}`;
    }
    if (search.deviceIP != null && search.deviceIP != '') {
      ApiUrl += `&deviceIP=${search.deviceIP}`;
    }
    if (search.macAddress != null && search.macAddress != '') {
      ApiUrl += `&macAddress=${search.macAddress}`;
    }
    if (search.barcode != null && search.barcode != '') {
      ApiUrl += `&barcode=${search.barcode}`;
    }
    if (search.deviceName != null && search.deviceName != '') {
      ApiUrl += `&deviceName=${search.deviceName}`;
    }
    if (search.serverNumber != null && search.serverNumber != '') {
      ApiUrl += `&serverNumber=${search.serverNumber}`;
    }
    return this.getData(ApiUrl);
  }

  addDecommissionedInventory(AddData: any) {
    let ApiUrl = `decom-rmv`;
    let data: any = {
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updateDecommissionedInventory(AddData: any) {
    let ApiUrl = `decom-rmv`;
    let data: any = {
      deviceId: AddData.deviceId,
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }

  makeDecommissionedOnline(deviceId: number) {
    let ApiUrl = `decom-rmv`;
    let data: any = {
      deviceId: deviceId,
    };

    let requestJSON = JSON.stringify(data);
    return this.patchData(requestJSON, ApiUrl);
  }

  getSpareDeviceInventory(paginator: Page, search: SearchParam, serviceId: number, sortParam: any) {
    let ApiUrl = `spare?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (serviceId >= 0) {
      ApiUrl += `&serviceType=${serviceId}`;
    }
    if (search.siteId > 0) {
      ApiUrl += `&siteId=${search.siteId}`;
    }
    if (search.manufacturerId > 0) {
      ApiUrl += `&manufacturerId=${search.manufacturerId}`;
    }
    if (search.deviceTypeId > 0) {
      ApiUrl += `&deviceTypeId=${search.deviceTypeId}`;
    }
    if (search.deviceStatusCode != null && search.deviceStatusCode != '') {
      ApiUrl += `&deviceStatusCode=${search.deviceStatusCode}`;
    }
    if (search.switchName != null && search.switchName != '') {
      ApiUrl += `&switchName=${search.switchName}`;
    }
    if (search.portDetails != null && search.portDetails != '') {
      ApiUrl += `&portDetails=${search.portDetails}`;
    }
    if (search.deviceIP != null && search.deviceIP != '') {
      ApiUrl += `&deviceIP=${search.deviceIP}`;
    }
    if (search.macAddress != null && search.macAddress != '') {
      ApiUrl += `&macAddress=${search.macAddress}`;
    }
    if (search.barcode != null && search.barcode != '') {
      ApiUrl += `&barcode=${search.barcode}`;
    }
    if (search.deviceName != null && search.deviceName != '') {
      ApiUrl += `&deviceName=${search.deviceName}`;
    }
    if (search.serverNumber != null && search.serverNumber != '') {
      ApiUrl += `&serverNumber=${search.serverNumber}`;
    }
    return this.getData(ApiUrl);
  }

  addSpareInventory(AddData: any) {
    let ApiUrl = `spare`;
    let data: any = {
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updateSpareInventory(AddData: any) {
    let ApiUrl = `spare`;
    let data: any = {
      deviceId: AddData.deviceId,
      deviceName: AddData.deviceName,
      deviceTypeId: AddData.deviceTypeId,
      deviceStatusCode: AddData.deviceStatusCode,
      manufacturerId: AddData.manufacturerId,
      siteId: AddData.siteId,
      macAddress: AddData.macAddress,
      warrantyStart: AddData.warrantyStart,
      warrantyEnd: AddData.warrantyEnd,
      serverNumber: AddData.serverNumber,
      partNumber: AddData.partNumber,
      barcode: AddData.barcode,
      deviceIP: AddData.deviceIP,
      firmwareVersion: AddData.firmwareversion,
      serviceType: ApiService.serviceId,
      portDetails: AddData.portDetails,
      switchName: AddData.switchName,
      quarter: AddData.quarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  makeSpareOnline(deviceId: number) {
    let ApiUrl = `spare`;
    let data: any = {
      deviceId: deviceId,
    };

    let requestJSON = JSON.stringify(data);
    return this.patchData(requestJSON, ApiUrl);
  }
  //Users mangment
  getUsers(paginator: Page) {
    let ApiUrl = `user/getUser?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    return this.getData(ApiUrl);
  }
  //users with out pagenate
  getAllUsers(search: searchUser) {
    let ApiUrl = `list/all-users?`;
    if (search.siteId > 0) {
      ApiUrl += `&siteId=${search.siteId}`;
    }
    if (search.userTypeId > 0) {
      ApiUrl += `&userTypeId=${search.userTypeId}`;
    }
    return this.getData(ApiUrl);
  }
  addNewUser(AddData: any) {
    let ApiUrl = `user/store`;
    let data: any = {
      username: AddData.username,
      password: AddData.password,
      email: AddData.email,
      phone: AddData.phone,
      userTypeId: AddData.userTypeId,
      companyId: AddData.companyId,
      account_status: AddData.account_status
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateUser(AddData: any) {
    let ApiUrl = `user/update`;
    let data: any = {
      userId: AddData.userId,
      username: AddData.username,
      email: AddData.email,
      phone: AddData.phone,
      userTypeId: AddData.userTypeId,
      companyId: AddData.companyId,
      account_status: AddData.account_status,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  deleteUser(id: number) {
    let ApiUrl = `user/delete`;
    let data: any = {
      userId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  //user profile
  getUserProfile() {
    let ApiUrl = `profile/show`;
    return this.getData(ApiUrl);
  }
  updateUserProfile(data: FormData) {
    let ApiUrl = `profile/update-info`;
    return this.putFromData(data, ApiUrl);
  }
  updateImageProfile(image: FormData) {
    let ApiUrl = `profile/update-image`;
    return this.putFromData(image, ApiUrl);
  }
  // ------End of Profile ------

  //get Device By ManufacturersId

  getDeviceByManufacturersPaginate(paginator: Page) {
    let ApiUrl = `dashboard/devices/devices-manufacturers`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    return this.getData(ApiUrl);
  }
  getDeviceByManufacturers() {
    let ApiUrl = `manufacturer`;
    return this.getData(ApiUrl);
  }
  getCountries() {
    let ApiUrl = `country`;
    return this.getData(ApiUrl);
  }
  //get Device By Site Id

  getDeviceBySiteId(paginator: Page) {
    let ApiUrl = `dashboard/devices/devices-site`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    return this.getData(ApiUrl);
  }
  //get Device By Status Id

  getDeviceByStatusId(paginator: Page, id: number) {
    let ApiUrl = `dashboard/devices/devices-status`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    if (id > 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    return this.getData(ApiUrl);
  }
  //get Device By Type Id

  getDeviceByTypeId(paginator: Page) {
    let ApiUrl = `dashboard/devices/devices-type`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }

    return this.getData(ApiUrl);
  }

  //Crud Manufacturer

  addNewManufacturer(AddData: any) {
    let ApiUrl = `manufacturer`;
    let data: any = {
      manufacturerName: AddData.manufacturerName,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  addNewCountry(AddData: any) {
    let ApiUrl = `country`;
    let data: any = {
      countryName: AddData.countryName,
      countryCode: AddData.countryCode,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updateManufacturer(AddData: any) {
    let ApiUrl = `manufacturer`;
    let data: any = {
      manufacturerName: AddData.manufacturerName,
      manufacturerId: AddData.manufacturerId,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  updateCountry(AddData: any) {
    let ApiUrl = `country`;
    let data: any = {
      countryName: AddData.countryName,
      countryCode: AddData.countryCode,
      countryId: AddData.countryId,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deletManufacturer(id: number) {
    let ApiUrl = `manufacturer`;
    let data: any = {
      manufacturerId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  deletCountry(id: number) {
    let ApiUrl = `country`;
    let data: any = {
      countryId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  getSitesT(paginator: Page) {
    let ApiUrl = `site/get-sites?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    return this.getData(ApiUrl);
  }

  addNewSite(AddData: any) {
    let ApiUrl = `site`;
    let data: any = {
      siteName: AddData.siteName,
      siteShortcut: AddData.siteShortcut,
      countryId: AddData.countryId,
      companyId: AddData.companyId,
      latitude: AddData.latitude?.toString(),
      longitude: AddData.longitude?.toString(),
      cityName: AddData.cityName,
      area: AddData.area
    };
    if(AddData.storeNumber)
      data['storeNumber'] = AddData.storeNumber;
    if(AddData.phone)
      data['phone'] = AddData.phone;
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updateSite(AddData: any) {
    let ApiUrl = `site`;
    let data: any = {
      siteName: AddData.siteName,
      countryId: AddData.countryId,
      siteShortcut: AddData.siteShortcut,
      siteId: AddData.siteId,
      companyId: AddData.companyId,
      latitude: AddData.latitude?.toString(),
      longitude: AddData.longitude?.toString(),
    };
    if(AddData.storeNumber)
      data['storeNumber'] = AddData.storeNumber;
    if(AddData.cityName)
      data['cityName'] = AddData.cityName;
    if(AddData.area)
      data['area'] = AddData.area;
    if(AddData.phone)
      data['phone'] = AddData.phone;
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deletSite(id: number) {
    let ApiUrl = `site/delete`;
    let data: any = {
      siteId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  getDeviceType() {
    let ApiUrl = `device_type`;
    return this.getData(ApiUrl);
  }
  getCompanies() {
    let ApiUrl = `company`;
    return this.getData(ApiUrl);
  }

  addNewDeviceType(AddData: any) {
    let ApiUrl = `device_type`;
    let data: any = {
      deviceType: AddData.deviceType,
      deviceImage: AddData.deviceImage,
    };
    let form_data = new FormData();
    form_data.append('deviceType', JSON.stringify(data.deviceType));
    form_data.append('image', data.deviceImage);
    return this.postFromData(form_data, ApiUrl);
  }
  addNewCompany(AddData: any) {
    let ApiUrl = `company`;
    let data: any = {
      countryId: AddData.countryId,
      companyName: AddData.companyName,
      mobileNumber: AddData.mobileNumber,
      ticketEmail: AddData.ticketEmail,
      ppmEmail: AddData.ppmEmail,
      addrss: AddData.addrss,
      companyStatus: AddData.companyStatus,
      phoneNumber: AddData.phoneNumber,
      includeHoliday: AddData.includeHoliday,
      saturday: AddData.saturday,
      sunday: AddData.sunday,
      monday: AddData.monday,
      tuesday: AddData.tuesday,
      wednesday: AddData.wednesday,
      thursday: AddData.thursday,
      friday: AddData.friday,
      utc_diff: AddData?.utc_diff,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updateDeviceType(AddData: any) {
    let ApiUrl = `device_type`;
    let data: any = {
      deviceType: AddData.deviceType,
      deviceTypeId: AddData.deviceTypeId,
      deviceImage: AddData.deviceImage,
    };
    let form_data = new FormData();
    form_data.append('deviceType', JSON.stringify(data.deviceType));
    if (data.deviceImage) {
      form_data.append('image', data.deviceImage);
    }
    form_data.append('deviceTypeId', data.deviceTypeId);
    let requestJSON = JSON.stringify(data);
    return this.putFromData(form_data, ApiUrl);
  }
  updateCompany(AddData: any) {
    let ApiUrl = `company`;
    let data: any = {
      countryId: AddData.countryId,
      companyId: AddData.companyId,
      companyName: AddData.companyName,
      mobileNumber: AddData.mobileNumber,
      ticketEmail: AddData.ticketEmail,
      ppmEmail: AddData.ppmEmail,
      addrss: AddData.addrss,
      companyStatus: AddData.companyStatus,
      phoneNumber: AddData.phoneNumber,
      includeHoliday: AddData.includeHoliday,
      saturday: AddData.saturday,
      sunday: AddData.sunday,
      monday: AddData.monday,
      tuesday: AddData.tuesday,
      wednesday: AddData.wednesday,
      thursday: AddData.thursday,
      friday: AddData.friday,
      utc_diff: AddData.utc_diff,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deletDeviceType(id: number) {
    let ApiUrl = `device_type`;
    let data: any = {
      deviceTypeId: id,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  getTypesT() {
    let ApiUrl = `user/user-type?`;
    return this.getData(ApiUrl);
  }
  getCountrySiteSwitch() {
    let ApiUrl = `dashboard/devices/country-sites-switch?serviceType=${ApiService.serviceId}`;
    return this.getData(ApiUrl);
  }

  getCountrySiteServer() {
    let ApiUrl = `dashboard/devices/country-sites-servers?serviceType=${ApiService.serviceId}`;
    return this.getData(ApiUrl);
  }

  addNewType(AddData: any) {
    let ApiUrl = `user/user-type`;
    let data: any = {
      userType: AddData.userType,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  getUserType() {
    let ApiUrl = `user/user-type`;
    return this.getData(ApiUrl);
  }
  //reset password
  resetPassword(email: any) {
    let ApiUrl = `reset`;
    let data: any = {
      email: email,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  verify(AddData: any, token: any) {
    let ApiUrl = `reset/verify-email`;
    let data: any = {
      code: AddData.code,
    };
    let headers = {
      key: 'Authorization',
      value: 'Bearer ' + token
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl, headers);
  }
  changePassowd(AddData: any) {
    const uuid = new DeviceUUID().get();
    const du = new DeviceUUID().parse();
    let data: any = {
      password: AddData.password,
      platform: du.platform,
      uuid: uuid,
    };
    let ApiUrl = `reset/change-password`;
    let API_URL = `${this.apiUrl}/${ApiUrl}`;
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'token' + ' ' + AddData.token)
      .set('Accept', 'application/json');
    let requestJSON = JSON.stringify(data);

    return this.http.post(API_URL, requestJSON, { headers: this.headers, observe: 'response' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  getResolutionCategory() {
    let ApiUrl = `ticket/resolutionCategory`;
    return this.getData(ApiUrl);
  }
  addResolutionCategory(AddData: any) {
    let ApiUrl = `ticket/resolutionCategory`;
    let data: any = {
      resolutionCategoryName: AddData.resolutionCategoryName,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updatePassword(AddData: any) {
    let ApiUrl = `profile/update-password`;
    let data: any = {
      currentPassword: AddData.currentPassword,
      newPassword: AddData.newPassword,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  updateEmail(code: number, token: string) {
    let ApiUrl = `reset/email-change`;
    let data: any = {
      code: code,
      token: token,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  resetEmail(newEmail: string) {
    let ApiUrl = `reset/email`;
    let data: any = {
      newEmail: newEmail,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateResolutionCategory(AddData: any) {
    let ApiUrl = `ticket/resolutionCategory`;
    let data: any = {
      resolutionCategoryName: AddData.resolutionCategoryName,
      resolutionCategoryId: AddData.resolutionCategoryId,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deleteResolutionCategory(resolutionCategoryId: any) {
    let ApiUrl = `ticket/resolutionCategory`;
    let data: any = {
      resolutionCategoryId: resolutionCategoryId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }

  getResolution(resolutionCategoryId: any) {
    let ApiUrl = `reslution?serviceType=${ApiService.serviceId}&`;
    if (resolutionCategoryId > 0) {
      ApiUrl += `&resolutionCategoryId=${resolutionCategoryId}`;
    }
    return this.getData(ApiUrl);
  }
  addResolution(AddData: any) {
    let ApiUrl = `reslution`;
    let data: any = {
      resolutionName: AddData.resolutionName,
      resolutionCategoryId: AddData.resolutionCategoryId,
      serviceType: AddData.serviceType,
      // resolutionCategoryId:AddData.resolutionCategoryId,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateResolution(AddData: any) {
    let ApiUrl = `reslution`;
    let data: any = {
      resolutionName: AddData.resolutionName,
      resolutionId: AddData.resolutionId,
      serviceType: AddData.serviceType,
      resolutionCategoryId: AddData.resolutionCategoryId,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deleteResolution(AddData: any) {
    let ApiUrl = `reslution`;
    let data: any = {
      resolutionId: AddData.resolutionId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }

  getCompanyService() {
    let ApiUrl = `company/services`;
    return this.getData(ApiUrl);
  }
  addCompanyService(AddData: any) {
    let ApiUrl = `company/services`;
    let data: any = {
      companyId: AddData.companyId,
      servicesPriorites: AddData.servicesPriorites,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateCompanyService(AddData: any) {
    let ApiUrl = `company/services`;
    let data: any = {
      companyId: AddData.companyId,
      servicesPriorites: AddData.servicesPriorites,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  getReports(url: any) {

    var a = document.createElement('a');
    a.href = url;
    a.target = '_self';
    a.download = 'bill.pdf';
    document.body.appendChild(a);
    a.click();
  }
  getReportsExcel(url: any) {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_self';
    a.download = 'bill.xlsx';
    document.body.appendChild(a);
    a.click();
  }
  addService(AddData: any) {
    let ApiUrl = `service-type`;
    let data: any = {
      serviceName: AddData.serviceName,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateService(AddData: any) {
    let ApiUrl = `service-type`;
    let data: any = {
      serviceName: AddData.serviceName,
      serviceType: AddData.serviceType,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deleteServiceType(serviceType: any) {
    let ApiUrl = `service-type`;
    let data: any = {
      serviceType: serviceType,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  //Tickets
  createTicket(AddData: FormData) {
    let ApiUrl = `ticket/open/admin`;
    // let data: any = {
    //   ticketDescription: AddData.ticketDescription,
    //   deviceIds: AddData.deviceIds,
    //   siteId: AddData.siteId,
    //   clientId: AddData.clientId,
    //   ticketStatusCode: AddData.ticketStatusCode,
    //   incidentCategoryCode: AddData.incidentCategoryCode,
    //   incidentPriorityCode: AddData.incidentPriorityCode,
    //   technicalId: AddData.technicalId,
    //   engineerId: AddData.engineerId,
    //   serviceType: AddData.serviceType,
    //   image:AddData.image
    // };

    // let requestJSON = JSON.stringify(data);
    return this.postFromData(AddData, ApiUrl);
  }
  updateTicket(AddData: any) {
    let ApiUrl = `ticket`;
    let data: any = AddData;
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  updateTicketMaster(AddData: any) {
    let ApiUrl = `ticket-master`;
    let data: any = {
      ticketId: AddData.ticketId,
      ticketStatusCode: AddData.ticketStatusCode,
      engineerId: AddData.engineerId,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  updateTicketImg(AddData: FormData) {
    let ApiUrl = `ticket/image`;
    return this.postFromData(AddData, ApiUrl);
  }
  stopOrStat(AddData: any) {
    let ApiUrl = `ticket/start-stop`;
    let data: any = AddData;
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  openTicketByClient(AddData: FormData) {
    let ApiUrl = `ticket/open/client`;
    return this.postFromData(AddData, ApiUrl);
  }

  getTickes(paginator: Page, search: any) {
    let ApiUrl = `ticket?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    if (search.ticketStatusCode != null && search.ticketStatusCode != '') {
      ApiUrl += `&ticketStatusCode=${search.ticketStatusCode}`;
    }
    if (search.fromDate != null && search.fromDate != '') {
      ApiUrl += `&fromDate=${search.fromDate}`;
    }
    if (search.toDate != null && search.toDate != '') {
      ApiUrl += `&toDate=${search.toDate}`;
    }
    if (search.incidentPriorityCode != null && search.incidentPriorityCode != '') {
      ApiUrl += `&incidentPriorityCode=${search.incidentPriorityCode}`;
    }
    if (search.siteId > 0) {
      ApiUrl += `&siteId=${search.siteId}`;
    }
    if (search.uId > 0) {
      ApiUrl += `&uId=${search.uId}`;
    }
    if (search.deviceId > 0) {
      ApiUrl += `&deviceId=${search.deviceId}`;
    }
    if (search.serviceType > 0) {
      ApiUrl += `&serviceType=${search.serviceType}`;
    }
    if (search.ticketLable) {
      ApiUrl += `&ticketLable=${search.ticketLable}`;
    }

    return this.getData(ApiUrl);
  }
  getTickesMaster(paginator: Page, ticketsStatusCode?: any, engineerId?: number) {
    let ApiUrl = `ticket-master/group?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    if (engineerId > 0) {
      ApiUrl += `&engineerId=${engineerId}`;
    }
    if (ticketsStatusCode) {
      ApiUrl += `&ticketStatusCode=${ticketsStatusCode}`;
    }
    return this.getData(ApiUrl);
  }
  getTickesDashboard(search: any) {
    let ApiUrl = `ticket/dashboard?`;

    if (search.ticketStatusCode != null && search.ticketStatusCode != '') {
      ApiUrl += `&ticketStatusCode=${search.ticketStatusCode}`;
    }
    if (search.serviceType > 0) {
      ApiUrl += `&serviceType=${search.serviceType}`;
    }
    return this.getData(ApiUrl);
  }
  getServerNumber(siteId: number) {
    let ApiUrl = `device-inventory/servers?siteId=${siteId}&serviceType=${ApiService.serviceId}`;
    return this.getData(ApiUrl);
  }
  getGallery(ticketId: number) {
    let ApiUrl = `ticket/gallery?ticketId=${ticketId}`;
    return this.getData(ApiUrl);
  }
  getHistory(ticketId: number) {
    let ApiUrl = `ticket/history?ticketId=${ticketId}`;
    return this.getData(ApiUrl);
  }

  getticketStatus() {
    let ApiUrl = `ticket/status`;
    return this.getData(ApiUrl);
  }

  getInciedentCategory() {
    let ApiUrl = `ticket/inciedent-category`;
    return this.getData(ApiUrl);
  }

  getInciedentPriority() {
    let ApiUrl = `ticket/priority`;
    return this.getData(ApiUrl);
  }
  getInciedentPriorityList(clientId: number, serviceId: number) {
    let ApiUrl = `list/incident-priority-list?serviceType=${serviceId}&clientId=${clientId}`;
    return this.getData(ApiUrl);
  }
  getInciedentCategoriesList() {
    let ApiUrl = `list/incident-category-list?serviceType=${ApiService.serviceId}`;
    return this.getData(ApiUrl);
  }
  updatePriority(AddData: any) {
    let ApiUrl = `ticket/priority`;
    let data: any = {
      incidentPriorityId: AddData.incidentPriorityId,
      duration: AddData.duration,
      incidentPriorityColor: AddData.incidentPriorityColor,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }

  getTypePerSite(paginator: Page, siteId: number) {
    let ApiUrl = `dashboard/devices/devices-site?serviceType=${ApiService.serviceId}`;
    if (siteId > 0) {
      ApiUrl += `&siteId=${siteId}`;
    }
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&offset=${paginator.page}`;
    }
    return this.getData(ApiUrl);
  }
  getUserOperation(paginator: Page, search: UserOperationSearch) {
    let ApiUrl = `user-operations?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (search.operationId > 0) {
      ApiUrl += `&operationId=${search.operationId}`;
    }
    if (search.userId > 0) {
      ApiUrl += `&userId=${search.userId}`;
    }
    return this.getData(ApiUrl);
  }
  getComment(paginator: Page, ticketId: number) {
    let ApiUrl = `ticket/comment/?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (ticketId > 0) {
      ApiUrl += `&ticketId=${ticketId}`;
    }
    return this.getData(ApiUrl);
  }
  AssigneTo(AddData: any) {
    let ApiUrl = `ticket/assign`;
    let data: any = {
      ticketId: AddData.ticketId,
      assignedTo: AddData.assignedTo,
      ticketStatusCode: AddData.ticketStatusCode,
      action: AddData.action,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  addComment(AddData: FormData) {
    let ApiUrl = `ticket/comment/`;
    return this.postFromData(AddData, ApiUrl);
  }
  editComment(AddData: FormData) {
    let ApiUrl = `ticket/comment/`;
    return this.putFromData(AddData, ApiUrl);
  }
  deleteComment(commentId: number) {
    let ApiUrl = `ticket/comment/`;
    let data: any = {
      commentId: commentId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  getAttachment(paginator: Page, ticketId: number) {
    let ApiUrl = `ticket/attachment?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page + 1}`;
    }
    if (ticketId > 0) {
      ApiUrl += `&ticketId=${ticketId}`;
    }
    return this.getData(ApiUrl);
  }
  addAttachment(AddData: FormData) {
    let ApiUrl = `ticket/attachment`;
    return this.postFromData(AddData, ApiUrl);
  }
  editAttachment(AddData: FormData) {
    let ApiUrl = `ticket/attachment`;
    return this.putFromData(AddData, ApiUrl);
  }
  deleteAttachment(attachmentId: number) {
    let ApiUrl = `ticket/attachment`;
    let data: any = {
      attachmentId: attachmentId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }

  //PPM Reports & add
  ppmReport(paginator: Page) {
    let ApiUrl = `ppm/master/group-list-web?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    return this.getData(ApiUrl);
  }
  getppmByMaster(ppmMasterId: number) {
    let ApiUrl = `ppm/master/show?`;
    if (ppmMasterId > 0) {
      ApiUrl += `&ppmMasterId=${ppmMasterId}`;
    }
    return this.getData(ApiUrl);
  }
  getPPMByMasterGroup(paginator: Page, isArchived = false,objectFilter?) {
    let ApiUrl = `ppm/master/get-ppm-grouped-by-company?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&skip=${paginator.page * paginator.limit}`;
    }
    if (objectFilter?.ppmTypeId && objectFilter?.ppmTypeId>0) {
      ApiUrl += `&ppmTypeId=${objectFilter.ppmTypeId}`;
    }
    if (objectFilter?.companyId && objectFilter?.companyId>0) {
      ApiUrl += `&companyId=${objectFilter.companyId}`;
    }
    ApiUrl += `&isArchived=${isArchived}`;
    return this.getData(ApiUrl);
  }
  getPPMNested(paginator: Page, data?) {
    let ApiUrl = `ppm/master/get-ppm-grouped-by-company-nested?`;
    if (paginator.limit > 0) {
      ApiUrl += `&limit=${paginator.limit}`;
    }
    if (paginator.page >= 0) {
      ApiUrl += `&page=${paginator.page}`;
    }
    if (data.isCompleted != null) {
      ApiUrl += `&isCompleted=${data?.isCompleted}`;
    }
    if (data.companyId) {
      ApiUrl += `&companyId=${data?.companyId}`;
    }
    if (data.ppmTypeId) {
      ApiUrl += `&ppmTypeId=${data?.ppmTypeId}`;
    }
    if (data.ppmQuarter) {
      ApiUrl += `&ppmQuarter=${data?.ppmQuarter}`;
    }
    if (data.isAccepted !==null) {
      ApiUrl += `&isAccepted=${data?.isAccepted}`;
    }
    return this.getData(ApiUrl);
  }
  getFilterList() {
    let ApiUrl = `ppm/master/get-ppm-filter-list`; 
    return this.getData(ApiUrl);
  }

  addPPM(newData: any) {
    let ApiUrl = `ppm/master?`;
    let data: any = {
      siteId: newData.siteId,
      ppmTypeId: newData.ppmTypeId,
      engineerId: newData.engineerId,
      clientId: newData.clientId,
      technicians: newData.technicians,
      endAt: newData.endAt,
      contractQuarter: newData.contractQuarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  getListUser(userTypeId: number) {
    let ApiUrl = `list/all-users?`;
    if (userTypeId && userTypeId > 0) {
      ApiUrl += `&userTypeId=${userTypeId}`;
    }
    return this.getData(ApiUrl);
  }
  getppmType() {
    let ApiUrl = `ppm-types?`;
    return this.getData(ApiUrl);
  }
  getCheckList(ppmTypeId: number) {
    let ApiUrl = `ppm-items?`;
    if (ppmTypeId > 0) {
      ApiUrl += `ppmTypeId=${ppmTypeId}`;
    }
    return this.getData(ApiUrl);
  }
  AssignPPmTo(onChargeRole: number, ppmMasterId: number) {
    let ApiUrl = `ppm/master/assign`;
    let data: any = {
      onChargeRole: onChargeRole,
      ppmMasterId: ppmMasterId,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  UpdatePPM(dataJ: any) {
    let ApiUrl = `ppm/master/`;
    let data: any = dataJ;
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  UpdatePPMDetails(dataJ: any) {
    let ApiUrl = `ppm/detail/`;
    let data: any = dataJ;
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  ppmDetails(ppmMasterId: number, ppmTypeId: number, page: Page) {
    let ApiUrl = `ppm/detail`;
    let data: any = {
      ppmMasterId: ppmMasterId,
      ppmTypeId: ppmTypeId,
      limit: page.limit,
      page: page.page + 1,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  assignGroup(companyId: number, ppmTypeId: number, ppmQuarter: string) {
    let ApiUrl = `ppm/master/assign-ppm-group`;
    let data: any = {
      companyId: companyId,
      ppmTypeId: ppmTypeId,
      ppmQuarter: ppmQuarter,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  updatePPMDetailsImg(AddData: FormData) {
    let ApiUrl = `ppm/detail/image`;
    return this.putFromData(AddData, ApiUrl);
  }
  AcceptPPM(AddData: any) {
    let ApiUrl = `ppm/master/accept-ppm`;
    let data: any = {
      ppmMasterId: AddData.ppmMasterId,
      isAccepted: AddData.isAccepted,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  SubmitSignature(data: any) {
    let ApiUrl = `ppm/master/signature`;
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  // ---------------------Qutation-----------------
  getMS() {
    let ApiUrl = `quotation?`;
    return this.getData(ApiUrl);
  }

  addMS(AddData: any) {
    let ApiUrl = `quotation`;
    let data: any = {
      description: AddData.description,
      unit: AddData.unit,
      type: AddData.type,
      brand: AddData.brand,
      price: AddData.price,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  updateMS(AddData: any) {
    let ApiUrl = `quotation`;
    let data: any = {
      msId: AddData.msId,
      description: AddData.description,
      unit: AddData.unit,
      type: AddData.type,
      brand: AddData.brand,
      price: AddData.price,
    };
    let requestJSON = JSON.stringify(data);
    return this.putData(requestJSON, ApiUrl);
  }
  deleteMS(AddData: any) {
    let ApiUrl = `quotation`;
    let data: any = {
      msId: AddData.msId,
    };
    let requestJSON = JSON.stringify(data);
    return this.deleteData(requestJSON, ApiUrl);
  }
  //----------------Qutation master----------------

  getQuotation(page: Page, ticketId: number) {
    let ApiUrl = `quotation-master?`;
    if (page.limit > 0) {
      ApiUrl += `&limit=${page.limit}`;
    }
    if (page.page >= 0) {
      ApiUrl += `&page=${page.page + 1}`;
    }
    if (ticketId > 0) {
      ApiUrl += `&ticketId=${ticketId}`;
    }
    return this.getData(ApiUrl);
  }

  addQuotation(AddData: any) {
    let ApiUrl = `quotation`;
    let data: any = {
      description: AddData.description,
      unit: AddData.unit,
      type: AddData.type,
      brand: AddData.brand,
      price: AddData.price,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }

  userPerformanceWeb(AddData: any) {
    let ApiUrl = `reports/user-performance-web`;
    let data: any = {
      fromDate: AddData.description,
      ToDate: AddData.unit,
    };
    let requestJSON = JSON.stringify(data);
    return this.postData(requestJSON, ApiUrl);
  }
  exportUserPerformancePDF(AddData: any) {
    let ApiUrl = 'reports/user-performance-web';
    let API_URL = `${this.apiUrl}/${ApiUrl}`;
    let data: any = {
      fromDate: AddData.fromDate,
      toDate: AddData.toDate,
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    }
    let requestJSON = JSON.stringify(data);

    return this.http.post(API_URL, requestJSON, { headers: this.headers, responseType: 'blob' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  exportBreakdownPDF(AddData: any) {
    let ApiUrl = 'reports/break-down-web';
    let API_URL = `${this.apiUrl}/${ApiUrl}`;
    let data: any = {
      fromDate: AddData.fromDate,
      toDate: AddData.toDate,
      siteId: AddData.siteId,
      deviceIP: AddData.deviceIP,
    };
    if (ApiService.Token.length > 0) {
      this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', ApiService.access_type + ' ' + ApiService.Token)
        .set('Accept', 'application/json');
    } else {
      this.headers = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json');
    }
    let requestJSON = JSON.stringify(data);

    return this.http.post(API_URL, requestJSON, { headers: this.headers, responseType: 'blob' }).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  clearCacheEntry(API_NAME: String): void {
    const API_URL = `${this.apiUrl}/${API_NAME}`;
    this.cache.delete(API_URL);
  }

  clearCache(): void {
    console.log('Clearing entire API cache');
    this.cache.clear();
  }
}
