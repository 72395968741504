import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
} else {
  // In development mode, ensure Angular DevTools can detect the application
  console.log('Running in development mode - Angular DevTools should work');
  
  // This will help expose the ng object for Angular DevTools
  if (window) {
    (window as any).ng = (window as any).ng || {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(moduleRef => {
    // Expose Angular for DevTools in development mode
    if (!environment.production && window) {
      (window as any).ng.probe = moduleRef;
    }
  })
  .catch(err => console.log(err));